import { Form } from 'react-final-form';
import { useMemo } from 'react';
import { DemandFormProps } from '@eogile/agrements-common/src/demand/components/demandComponentTypes';
import { DemandFormTitle } from '@eogile/agrements-common/src/demand/components/DemandFormTitle';
import { FormMode, FormModeProvider } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import {
  isVisibleMinistryDataSection,
  isVisibleMinistryVerdictSection,
} from '@eogile/agrements-common/src/demand/demandFormUtils';
import arrayMutators from 'final-form-arrays';
import { DemandMinistryDataSection } from '@eogile/agrements-common/src/demand/components/DemandMinistryDataSection';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';
import { DemandMinistryVerdictSection } from '@eogile/agrements-common/src/demand/components/DemandMinistryVerdictSection';
import { DemandGeneralCommentSection } from '@eogile/agrements-common/src/demand/components/DemandGeneralCommentSection';
import { DemandAttachmentsSection } from '@eogile/agrements-common/src/demand/components/DemandAttachmentsSection';
import { SocieteMereDemand } from '../societeMereDemandTypes';
import { SocieteMereDemandFormValues } from '../societeMereDemandFormTypes';
import {
  buildInitialSocieteMereDemandFormValues,
  buildSocieteMereDemandFormCommand,
} from '../societeMereDemandFormUtils';
import { SocieteMereDemandGeneralInformationSection } from './SocieteMereDemandGeneralInformationSection';
import { SocieteMereLinkedDemandsSection } from './SocieteMereLinkedDemandsSection';
import { SocieteMereDemandPersonSection } from './SocieteMereDemandPersonSection';
import { SocieteMereDemandAddressSection } from './SocieteMereDemandAddressSection';
import { SocieteMereDemandRequesterSection } from './SocieteMereDemandRequesterSection';
import { SocieteMereDemandRequesterPartnerSection } from './SocieteMereDemandRequesterPartnerSection';
import { useDemandFormSubmitHelper } from '@eogile/agrements-common/src/demand/hooks/useDemandFormSubmitHelper';
import { DemandActions } from '@eogile/agrements-common/src/demand/components/actions/DemandActions';

export const SocieteMereDemandForm = (props: DemandFormProps<SocieteMereDemand>) => {
  const { demand, mode, onSubmitSuccess, onDemandRefresh } = props;
  const submitForm = useDemandFormSubmitHelper<SocieteMereDemand>(mode, demand?.id);
  const user = useAuthenticatedCurrentUser();
  const initialValues = useMemo<SocieteMereDemandFormValues>(
    () => buildInitialSocieteMereDemandFormValues(demand),
    [demand],
  );

  const onSubmit = async (values: SocieteMereDemandFormValues) => {
    return await submitForm(buildSocieteMereDemandFormCommand(values), onSubmitSuccess!);
  };

  return (
    <FormModeProvider mode={mode}>
      <Form<SocieteMereDemandFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DemandFormTitle demand={demand} />
            <SocieteMereDemandGeneralInformationSection demand={demand} />
            <SocieteMereLinkedDemandsSection demand={demand} />
            <SocieteMereDemandPersonSection demand={demand} />
            <SocieteMereDemandAddressSection />
            <SocieteMereDemandRequesterSection demand={demand} />
            <SocieteMereDemandRequesterPartnerSection demand={demand} />
            {isVisibleMinistryDataSection(demand) && <DemandMinistryDataSection demand={demand} />}
            {isVisibleMinistryVerdictSection(demand, user) && (
              <DemandMinistryVerdictSection verdict={demand.verdict} variant={'NO_CANCELLATION_REASON'} />
            )}
            {mode === FormMode.READ && <DemandAttachmentsSection demand={demand} />}
            <DemandGeneralCommentSection />
            <DemandActions demand={demand} onDemandRefresh={onDemandRefresh} />
          </form>
        )}
      </Form>
    </FormModeProvider>
  );
};
